<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Rastreo de orden</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-6">
            <label for="username">Orden a rastrear</label>
            <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                        TRK
                    </span>
            <InputText id="username" type="text" v-model="trackID" @keyup.enter="trackOrder"/>
              </div>
          </div>
          <div class="field col-2">
            <label for="username">Fecha de creación</label>
            <InputText id="username" type="text" v-model="trackedData.date" readonly/>
          </div>
          <div class="field col-4">
            <label for="username">Última fecha solicitada recolección/entrega</label>
            <InputText id="username" type="text" v-model="trackedData.selectedDate" readonly/>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-5">
            <label for="username">Nombre del cliente</label>
            <InputText id="username" type="text" v-model="trackedData.displayName" readonly/>
          </div>
          <div class="field col-4">
            <label for="username">Último estatus de orden</label>
            <InputText id="username" type="text" v-model="trackedData.state" readonly/>
          </div>
          <div class="field col-3">
            <label for="username">Teléfono de contacto</label>
            <InputText id="username" type="text" v-model="trackedData.phoneNumber" readonly/>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-8">
            <label for="username">Dirección</label>
            <InputText id="username" type="text" v-model="trackedData.qualifiedAddress" readonly/>
          </div>
          <div class="field col-4">
            <label for="username">Coordenadas del lugar</label>
            <InputText id="username" type="text" v-model="trackedData.latLng" readonly/>
          </div>
        </div>
        <Button label="Ver en Google Maps®" :disabled="buttonDisabled" @click="openMapsMap"/>

        <h5>Historial de la orden</h5>
        <DataTable :value="stateHistoryAns" responsiveLayout="scroll" :sortOrder="1" sortField="dateTime">
          <Column field="dateTime" header="Fecha y hora" :sortable="true"></Column>
          <Column field="event" header="Actualización"></Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!--<Button label="Clonar" @click="generatePickupRecordByPreviousID('TRK0012180822730')"/>-->
  <Toast />
</template>

<script>
import { auth, pickup, stateHistory } from "../logic/firebase";
let trackedData = {
  calle: null,
  colonia: null,
  date: null,
  displayName: null,
  lat: null,
  lng: null,
  number: null,
  phoneNumber: null,
  selectedDate: null,
  state: null,
  time: null,
  trkID: null,
  uid: null,
  latLng: null,
  qualifiedAddress: null
}
let trackID = null
let stateHistoryAns = []
let buttonDisabled = true
let buttonLink = null
//https://www.google.com/maps/search/?api=1&query=20.685232872522217%2C-101.39401095182043

var moment = require('moment');
export default {
  data() {
    return {
      trackedData: trackedData,
      trackID: trackID,
      stateHistoryAns: stateHistoryAns,
      buttonDisabled: buttonDisabled,
      buttonLink: buttonLink,
      moment: moment
    }
  },


  methods: {
    trackOrder(){
      this.emptyVars();
      pickup.where('trkID', '==', "TRK" + this.trackID).limit(1).get().then((querySnapshot) => {
        if(querySnapshot.size === 1){
          this.displayToast('success', 'Scan exitoso',
              'ID de rastreo válido.', 2000);
          let data = querySnapshot.docs[0].data()
          const creationDateSplit = data.date.split('-');
          const formattedCreationDate = creationDateSplit[2] + "-" + creationDateSplit[1] + "-" + creationDateSplit[0];
          const requestedDateSplit = data.selectedDate.split('-');
          const formattedRequestedDate = requestedDateSplit[2] + "-" + requestedDateSplit[1] + "-" + requestedDateSplit[0];
          data.date = formattedCreationDate;
          data.selectedDate = formattedRequestedDate;
          data.latLng = data.lat + "," + data.lng;
          data.qualifiedAddress = data.calle + " " + data.number + ", " + data.colonia;
          this.trackedData = data
          this.buttonLink = "https://www.google.com/maps/search/?api=1&query=" + data.lat + "%2C" + data.lng;
          this.buttonDisabled = false;
          stateHistory.doc("TRK" + this.trackID).get().then((doc) => {
            if(doc.exists){
              let stateObj = doc.data().stateObj;

              for(let key in stateObj){
                let tmp = {};
                let dateSplit = key.split('-');
                tmp.dateTime = key//dateSplit[2].split(' ')[0] + "-" + dateSplit[1] + "-" + dateSplit[0] + " " + dateSplit[2].split(" ")[1]
                tmp.event = stateObj[key].state
                this.stateHistoryAns.push(tmp)
              }
            }else{
              console.log("No state history found for track ID TRK" + this.trackID);
            }
          })
        }else{
          console.log("Invalid track ID")
          this.displayToast('error', 'ID inválido',
              'El ID de rastreo escaneado no es válido.', 2000);
        }
      })
    },//trackOrder

    openMapsMap(){
      window.open(this.buttonLink, "_blank");
    },//openMapsMap

    emptyVars(){
      this.trackedData = {
        calle: null,
        colonia: null,
        date: null,
        displayName: null,
        lat: null,
        lng: null,
        number: null,
        phoneNumber: null,
        selectedDate: null,
        state: null,
        time: null,
        trkID: null,
        uid: null,
        latLng: null,
        qualifiedAddress: null
      };
      this.stateHistoryAns = []
      this.buttonDisabled = true
      this.buttonLink = null
    },//emptyVars

    displayToast(severity, summary, details, life){
      /*
       * SEVERITY: success, info, warn, error
       */
      this.$toast.add({severity: severity, summary: summary, detail: details, life: life});
    },//displayToast

    async generatePickupRecordByPreviousID(trkID){

      await pickup.where('trkID', '==', trkID).limit(1).get().then(async (querySnapshot) => {
        let newData = querySnapshot.docs[0].data();
        newData.trkID = this.generateTrackID();
        newData.state = 'Orden recolectada';
        await pickup.add(newData).then(async (docRef) => {
        }).catch(async (error) => {
        })
      })
    },

    generateTrackID(){
      var trkID = "TRK00" + this.moment().format('mmHHMMYYSSS');
      return trkID;
    },
  },


  mounted() {
    this.stateHistoryAns = []
    if(this.$route.query.trkID){
      this.trackID = this.$route.query.trkID.substring(3);
      this.trackOrder();
    }
  },
}
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>